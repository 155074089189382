import { useContext } from "react";
import MonopolyCard from "../components/MonopolyCard";
import { Player, StateContext } from "../GameState";
import { StyleSheet, css } from 'aphrodite';
import Die from "../components/Die";
import { PlayerProperty } from "../client/Properties";
import PlayerIcon from "../components/PlayerIcon";

const styles = StyleSheet.create({
    playerCard: {
        width: `calc(50vw - 50vh - 16px - 80px)`,
        marginBottom: 16,
    },

    name: {
        fontFamily: 'monopolyBold',
        fontSize: '1.5rem',
        margin: 0,
    },
    stat: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dice: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },


    property: {
        flexShrink: 0,
        height: 100,
        width: 80,

        border: '1px solid black',

        borderRadius: 4,
        marginRight: 16,
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        boxShadow: '4px 4px 8px rgba(0,0,0,0.5)',
        marginBottom: 10,
    },
    propertyList: {
        overflow: 'auto',
        display: 'flex',
        marginTop: 10,
    },

    propertyHeader: {
        width: '100%',
        minHeight: 18,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    propertyName: {
        textAlign: 'center',
        marginTop: 8,
        fontSize: '0.7rem',
    },
    propertyPrice: {
        textAlign: 'center',
        marginBottom: 8,
        fontSize: '0.7rem',
    },
    propertyData: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '2px 16px',
        fontSize: '0.9rem',
    },
    propertyDivider: {
        width: 'calc(100% - 16px)',
        margin: '4px 8px',
        borderTop: '1px solid #ccc',
    },

    icon: {
        backgroundColor: 'white',
        margin: 4,
        borderRadius: '2px',
        width: 10,
        height: 10,

        fontSize: '8px',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

interface Props {
    player: Player;
}

function PropertyCard({property}: { property: PlayerProperty}) {
    return (
        <div className={css(styles.property)}>
            <div className={css(styles.propertyHeader)} style={{ backgroundColor: property.color }}>
                {property.houses >= 5 ? (
                    <div className={css(styles.icon)}>
                        🏨
                    </div>
                ) : ([false, false, false, false, false].fill(true, 0, property.houses).map((k, i) => k && (
                    <div key={i} className={css(styles.icon)}>
                        🏠
                    </div>
                )))}
            </div>
            <div className={css(styles.propertyName)}>
                {property.name}
            </div>
            <hr className={css(styles.propertyDivider)} />
            {(property.mortgaged ? (
                <>
                    <p style={{margin: '0 auto', fontSize: '0.7rem', color: 'red'}}>MORTGAGED</p>
                </>
            ) : (
                <>
                    <div className={css(styles.propertyData)}>
                        <small>Rent</small>
                        <small>${property.rent[property.houses]}</small>
                    </div>
                </>
            ))}
            <hr className={css(styles.propertyDivider)} />
                    <div style={{ flexGrow: 1 }}>
                    </div>
                    <div className={css(styles.propertyPrice)}>
                        ${property.price}
                    </div>
        </div>
    );
}

function PlayerCard(props: Props) {
    return (
        <MonopolyCard styles={[styles.playerCard]}>
            <div>
                <div className={css(styles.stat)}>
                    <div className={css(styles.dice)}>
                        <div style={{ padding: '0 10px 0 0'}}>
                            <PlayerIcon value={props.player.data.icon ?? 0} small={true} size={26} padding={2} />
                        </div>
                        <p className={css(styles.name)}>
                            {props.player.data.name}                        
                        </p> 
                        <div style={{ width: 16}} />
                        <Die value={props.player.data?.lastDice?.[0] ?? 1} scale={0.2} />
                        <div style={{ width: 4}} />
                        <Die value={props.player.data?.lastDice?.[1] ?? 1} scale={0.2} />              
                    </div>                   
                    <p className={css(styles.name)}>
                        {props.player.data.money ?? 0}$
                    </p>
                </div>
                { props.player.data?.properties?.length > 0 && (
                    <div className={css(styles.propertyList)}>
                        {props.player.data.properties.sort((a: PlayerProperty, b: PlayerProperty) => a.space - b.space).map((property: PlayerProperty) => (
                            <PropertyCard key={property.name} property={property} />
                        ))}                        
                    </div>
                )}
            </div>
        </MonopolyCard>
    );
}
// {state.players[key].data.name ?? state.players[key].name} - {state.players[key].data.money ?? 0}$
export default function PlayerList() {
    const state = useContext(StateContext);

        return (
            <>
                <h3 style={{ margin: 0, marginBottom: 8 }}>Players</h3>
                <div>
                    {Object.keys(state.players).map((key) => (
                        <PlayerCard key={key} player={state.players[key]} />
                    ))}
                </div>
            </>
        );
    }