import React from "react";
import useMessage from "./useMessage";
import useRoomID from "./useRoomID";

export interface Player {
    name: string;
    data: { [key: string]: any };
}

interface State {
    players: {
        [key: string]: Player;
    };
}

interface UpdateProps {
    setContext: (state: State) => void;
}

export const StateContext = React.createContext<State & UpdateProps>({
    players: {},
    setContext: () => { },
});

interface Props {
    children: any,
}

export default function GameState({ children }: Props) {
    const [state, setState] = React.useState<State>({
        players: {},
    });

    const stateMemo = React.useMemo(() => ({
        ...state,
        setContext: setState,
    }), [state, setState]);

    useMessage('setData', (data) => {
        setState({
            ...state,
            players: {
                ...state.players,
                [data.sender]: {
                    ...state.players[data.sender],
                    data: {
                        ...state.players[data.sender]?.data,
                        ...data.data,
                    },
                },
            }, 
        });
    }, [state, setState]);

    useMessage('join', (data) => {
        setState({
            ...state,
            players: {
                ...state.players,
                [data.sender]: {
                    name: data.sender,
                    data: {},
                },
            },
        });
    }, [state, setState]);

    useMessage('leave', (data) => {
        const newp = state.players;
        delete newp[data.sender];

        setState({
            ...state,
            players: newp,
        });
    }, [state, setState]);


    return (
        <StateContext.Provider value={stateMemo}>
            {children}
        </StateContext.Provider>
    );
}