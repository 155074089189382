import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { WebsocketContext } from "../Connection";
import useMessage from "../useMessage";
import useRoomID from "../useRoomID";

interface Props {
    children: any;
}

interface TData {
    [key: string]: any;
}

interface TLocalState {
    current: TData,
    update: (state: { [key: string]: any }) => void,
}

export const LocalStateContext = React.createContext<TLocalState>({
    current: {},
    update: () => {},
});

export default function LocalState({children}: Props) {
    const [mounted, setMounted] = useState(false);
    const websocket = useContext(WebsocketContext);
    const [localState, setLocalState] = useState<TData>({});
    const [roomID] = useRoomID();

    useEffect(() => {
        const prevData = window.localStorage.getItem("PREV_DATA_" + roomID);

        if (prevData) {
            const parsed = JSON.parse(prevData);
            setLocalState(parsed);
            websocket.current?.send(JSON.stringify({
                type: 'setData',
                data: {
                    ...parsed,
                },
            }));
        }
        setMounted(true);
        websocket.current?.send(JSON.stringify({
            type: 'mounted',
        }));
    }, [roomID, setLocalState, websocket]);

    useEffect(() => {
        window.localStorage.setItem("PREV_DATA_" + roomID, JSON.stringify(localState));

        websocket.current?.send(JSON.stringify({
            type: 'setData',
            data: {
                ...localState,
            },
        }));
    }, [roomID, localState, websocket]);

    const joinCb = useCallback(() => {
        websocket.current?.send(JSON.stringify({
            type: 'setData',
            data: {
                ...localState,
            },
        }));
    }, [localState, websocket]);

    useMessage('mounted', joinCb, [localState]);

    const state = useMemo(() => ({
        current: localState,
        update: (state: { [key: string]: any }) => {
            setLocalState({...localState, ...state});
        },
    }), [localState, setLocalState]);

    if (!mounted) {
        return null;
    }

    return <LocalStateContext.Provider value={state}>{children}</LocalStateContext.Provider>;
}