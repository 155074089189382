
import { StyleSheet, css } from 'aphrodite';
import { useContext, useState } from 'react';
import MonopolyButton from '../components/MonopolyButton';
import { StateContext } from '../GameState';
import { LocalStateContext } from './LocalState';
import { MoneySuggestionContext } from './MoneySuggestion';
import { PropertyList, TProperty } from './PropertyList';

export interface PlayerProperty extends TProperty {
    houses: number;
    mortgaged: boolean;
}

const styles = StyleSheet.create({
    property: {
        flexShrink: 0,
        height: 215,
        width: 150,
        border: '1px solid black',
        borderRadius: 4,
        marginRight: 16,
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        boxShadow: '4px 4px 8px rgba(0,0,0,0.5)',
        marginBottom: 10,
    },
    propertyList: {
        overflow: 'auto',
        display: 'flex',
    },
    propertyHeader: {
        width: '100%',
        minHeight: 34,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    propertyName: {
        textAlign: 'center',
        marginTop: 8,
        fontSize: '1.2rem',
    },
    propertyPrice: {
        textAlign: 'center',
        marginBottom: 8,
    },
    propertyData: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '2px 16px',
    },
    propertyDivider: {
        width: 'calc(100% - 16px)',
        margin: '4px 8px',
        borderTop: '1px solid #ccc',
    },


    addProperty: {
        justifyContent: 'center',
        alignContent: 'center',

        fontSize: '3rem',
        flexShrink: 0,
        height: 200,
        width: 150,
        border: '1px solid black',
        borderRadius: 4,
        marginRight: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        backgroundColor: 'white',
        margin: 4,
        borderRadius: '8px',
        width: 25,
        height: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    submenu: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    submenuButton: {
        flexGrow: 1,
        fontSize: '1rem',
    },
});

interface Props extends PlayerProperty {
    remove: (id: string) => void;
}

function Property(props: Props) {
    const [backside, setBackside] = useState(false);
    const { current: { properties }, update } = useContext(LocalStateContext);
    const { addSuggestion } = useContext(MoneySuggestionContext);

    const updateProperty = (newp: any) => {
        const newProps = properties.map((p: PlayerProperty) => p.name === props.name ? {
            ...p,
            ...newp,
        } : p);
        update({ properties: newProps });
    };

    return (
        <div className={css(styles.property)} onClick={() => setBackside(!backside)}>
            <div style={{ backgroundColor: props.color }} className={css(styles.propertyHeader)}>
                {props.houses >= 5 ? (
                    <div className={css(styles.icon)}>
                        🏨
                    </div>
                ) : ([false, false, false, false, false].fill(true, 0, props.houses).map((k, i) => k && (
                    <div key={i} className={css(styles.icon)}>
                        🏠
                    </div>
                )))}
            </div>
            <div className={css(styles.propertyName)}>
                {props.name}
            </div>
            <hr className={css(styles.propertyDivider)} />
            {backside ? (
                <div className={css(styles.submenu)}>                    
                    <MonopolyButton type="compact" className={css(styles.submenuButton)} onClick={() => {
                        if (props.houses < 5) {
                            updateProperty({ houses: props.houses + 1 });
                            props.housePrice && addSuggestion(-props.housePrice, false);
                        }
                    }}>
                        Add house
                    </MonopolyButton>
                    <MonopolyButton type="compact" className={css(styles.submenuButton)} onClick={() => {
                        if (props.houses > 0) {
                            updateProperty({ houses: props.houses - 1 });
                            props.housePrice && addSuggestion(props.housePrice, false);
                        }
                    }}>
                        Remove house
                    </MonopolyButton>
                    <MonopolyButton type="compact" className={css(styles.submenuButton)} onClick={() => {
                        if (!props.mortgaged) {
                            addSuggestion(props.mortgage, false);
                        } else {
                            addSuggestion(-Math.floor(1.1*props.mortgage), false);
                        }

                        updateProperty({ mortgaged: !props.mortgaged });
                    }}>
                        {props.mortgaged ? 'Unmortgage' : 'Mortgage'}
                    </MonopolyButton>
                    <MonopolyButton type="compact" className={css(styles.submenuButton)} onClick={() => {
                        props.remove(props.name);
                        addSuggestion(props.price + ((props.housePrice ?? 0) * props.houses), false);
                    }}>
                        Sell property
                    </MonopolyButton>
                </div>
            ) : (props.mortgaged ? (
                <>
                    <p style={{margin: '0 auto', color: 'red'}}>MORTGAGED</p>
                    <div className={css(styles.propertyData)}>
                        <small>Unmortgage</small>
                        <small>${Math.floor(props.mortgage * 1.1)}</small>
                    </div>

                </>
            ) : (
                <>
                    <div className={css(styles.propertyData)}>
                        <small>Rent</small>
                        <small>${props.rent[props.houses]}</small>
                    </div>
                    {props.housePrice && (
                        <div className={css(styles.propertyData)}>
                            <small>House</small>
                            <small>${props.housePrice}</small>
                        </div>
                    )}
                    <div className={css(styles.propertyData)}>
                        <small>Mortgage</small>
                        <small>${props.mortgage}</small>
                    </div>

                    <hr className={css(styles.propertyDivider)} />
                    <div style={{ flexGrow: 1 }}>

                    </div>
                    <div className={css(styles.propertyPrice)}>
                        ${props.price}
                    </div>
                </>
            )
            )}
        </div>
    );
}

function AddProperty({ addProperty }: { addProperty: (a: any) => void }) {
    const { addSuggestion } = useContext(MoneySuggestionContext);
    const { players } = useContext(StateContext);
    const { current: { properties: localProperties }, update } = useContext(LocalStateContext);

    const isPropertyTaken = (name: string) => {
        return Object.values(players).some((p) => {
            return p?.data?.properties?.some((prop: PlayerProperty) => prop.name === name);
        }) || localProperties?.some((p: PlayerProperty) => p.name === name);
    };

    return (
        <select className={css(styles.addProperty)} onChange={(event) => {
            if (event.target.value === '+') {
                return;
            }
            addProperty({
                ...PropertyList[event.target.value],
                houses: 0,
                mortgaged: false,
            });
            addSuggestion(-PropertyList[event.target.value].price, false);
            event.target.value = '+';
        }}>
            <option value="+">+</option>
            {Object.keys(PropertyList).map((key) => {
                return <option key={key} value={key} disabled={isPropertyTaken(key)} >{key}</option>;
            })}
        </select>
    );
}

export default function Properties() {
    const { current: { properties }, update } = useContext(LocalStateContext);

    return (
        <>
            <hr />
            <h2>Properties</h2>
            <div className={css(styles.propertyList)}>
                {properties?.sort((a: PlayerProperty, b: PlayerProperty) => a.space - b.space)?.map((property: PlayerProperty) => {
                    return (
                        <Property
                            key={property.name}
                            {...property}
                            remove={(id: string) => {
                                update({ properties: properties.filter((p: any) => p.name !== id) })
                            }}

                        />
                    );
                })}
                <AddProperty addProperty={(p) => update({ properties: [...(properties ?? []), p] })} />
            </div>
        </>
    );
}