import { useContext } from "react";
import MonopolyInput from "../components/MonopolyInput";
import PlayerIcon from "../components/PlayerIcon";
import { LocalStateContext } from "./LocalState";

export default function Name() {
    const { current: { name, icon }, update } = useContext(LocalStateContext);    

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
        }}>
            <PlayerIcon value={icon ?? 0} onClick={() => {
                update({ icon: icon + 1 });
            }} />
            <MonopolyInput placeholder="Nombre"
                fieldSize="big"
                value={name ?? ''}
                onChange={(event) => {
                    update({ name: event.currentTarget.value.trim() });
                }} />
        </div>
    );
}