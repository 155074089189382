import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
    face: {
        
        display: 'grid',
        gridTemplateAreas: `"a . c"
            "e g f"
            "d . b"`,

        flex: '0 0 auto',
        // margin: '16px',
        padding: '10px',
        width: '104px',
        height: '104px',


        backgroundColor: '#e7e7e7',
        boxShadow: 'inset 0 5px white, inset 0 -5px #bbb, inset 5px 0 #d7d7d7 inset -5px 0 #d7d7d7',
        borderRadius: '10%',
    },
    pip: {
        display: 'block',
        alignSelf: 'center',
        justifySelf: 'center',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        backgroundColor: '#333',
        boxShadow: 'inset 0 3px #111, inset 0 -3px #555',

        ':nth-child(2)': { gridArea: 'b'},
        ':nth-child(3)': { gridArea: 'c'},
        ':nth-child(4)': { gridArea: 'd'},
        ':nth-child(5)': { gridArea: 'e'},
        ':nth-child(6)': { gridArea: 'f'},
        ':nth-child(odd)': {
            ':last-child': {
                gridArea: 'g',
            }
        },
    },
    
});

interface Props {
    value: number;
    scale?: number;
}

export default function Die({ value, scale }: Props) {
    return (
        <div style={{            
            height: `${104 * (scale ?? 1)}px`,
            width: `${104 * (scale ?? 1)}px`,
            margin: `${16 * (scale ?? 1)}px`,            
        }}>
            <div className={css(styles.face)} style={{
                transform: `scale(${scale ?? 1}) translateY(${-(104 - 32) * 0.5 * (scale ?? 1)}px) translateX(${-(104 - 32) * 0.5 * (scale ?? 1)}px)`,
                transformOrigin: 'top left',
            }}>
                { Array(value).fill(0).map((_, i) => <span key={i} className={css(styles.pip)} />) }
            </div>
        </div>
    );
}