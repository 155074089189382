import { StyleSheet, css } from "aphrodite";
import { useRef } from "react";
import MonopolyButton from "./components/MonopolyButton";
import MonopolyInput from "./components/MonopolyInput";

import bg from './imgs/bg.jpg';
import logo from './imgs/logo.png';

const styles = StyleSheet.create({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        
        backgroundImage: `url(${bg})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'contain',
    },
    window: {
        position: "relative",
        borderRadius: 16,
        background: 'white',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',


        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: 16,
    },
    logo: {
        position: 'absolute',
        top: -70,
        left: `calc(50% - 190px)`,
        height: 100,

    },
});

// Generate random name
function generateName() {
    return Math.random().toString(36).substring(2, 4) + Math.random().toString(36).substring(2, 4);
}


export default function LandingPage() {
    const roomRef = useRef<HTMLInputElement>(null);
    const hostNew = () => {
        const room = generateName();
        window.location.pathname = '/host/' + room;
    };
    const join = () => {
        window.location.pathname = '/' + roomRef.current?.value;
    };

    return (
        <div className={css(styles.container)}>
            <div className={css(styles.window)}>
                <div style={{ height: 20 }}></div>
                <img src={logo} className={css(styles.logo)} alt="Monopoly" />
                <div>
                    <MonopolyButton onClick={hostNew}>
                        Host New Game
                    </MonopolyButton>                    
                </div>
                <div>
                    <MonopolyInput ref={roomRef} />                    
                    <MonopolyButton onClick={join}>
                        Join Game
                    </MonopolyButton>                    
                </div>

            </div>
        </div>
    );
}