import { StyleSheet, css } from "aphrodite";
import "./monopolyFont.css";


const styles = StyleSheet.create({
    button: {
        borderRadius: 16,
        background: 'white',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',

        width: 'fit-content',

        padding: 16,
    },
});

interface Props {
    children?: any;
    styles?: any;
}

export default function MonopolyCard(props: Props) {
    return (
        <div className={css(styles.button, ...(props.styles ?? []))}>
            {props.children}
        </div>
    );
}