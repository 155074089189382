import QRCode from 'react-qr-code';
import MonopolyCard from '../components/MonopolyCard';
import useRoomID from '../useRoomID';
import useWindowSize from '../useWindowSize';
import PlayerList from './PlayerList';

export default function LeftPanel() {
    const [room] = useRoomID();

    let url = `https://monopoly.kolhos.chichasov.es/${room}`;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        url = `http://192.168.0.30:3000/${room}`;
    }

    const [width, height] = useWindowSize();

    const bh = height;
    const space = Math.min((width - bh) / 2 - 32, 200);

    return (
        <>
            <div style={{ padding: 16, zIndex: 2 }}>
                <MonopolyCard>
                    <QRCode value={url} size={space} />
                </MonopolyCard>
            </div>        
        </>
    );
}