import { useContext } from "react";
import MonopolyButton from "../components/MonopolyButton";
import MonopolyInput from "../components/MonopolyInput";
import { LocalStateContext } from "./LocalState";
import { MoneySuggestionContext } from "./MoneySuggestion";

export default function Money() {
    const { current: { money }, update } = useContext(LocalStateContext);
    const { suggestions, removeSuggestion } = useContext(MoneySuggestionContext);

    return (
        <>
            <hr />
            <h2 style={{ margin: '16px 0' }}>Money</h2>
            <MonopolyInput 
                value={money ?? '0'}
                style={{
                    fontSize: "2rem",
                    width: "100%",
                }}
                type="number"
                fieldSize="big"

                onChange={(event) => {
                    update({ money: parseInt(event.currentTarget.value ?? '0') ?? 0 });
                }} />
            
            {suggestions?.length ? (
                <div>
                    <p style={{ fontFamily: 'monopolyDouble', margin: '12px 0 0 0' }}>Suggestions</p>
                    <div>
                    {suggestions.map((suggestion, index) => (
                        <MonopolyButton
                        key={index}
                            style={{
                                margin: '6px 8px',
                                fontSize: '1rem',
                            }}
                            onClick={() => {
                                update({ money: money + suggestion.value });
                                removeSuggestion(index);
                            }}
                        >
                        {suggestion.value > 0 ? '+' : ''}{suggestion.value}$
                        </MonopolyButton>
                    ))}
                    </div>
                </div>
            ) : null}

        </>
    );
}