import { useContext, useEffect, useRef, useState } from "react";
import Die from "../components/Die";
import MonopolyButton from "../components/MonopolyButton";
import { LocalStateContext } from "./LocalState";

export default function Dice() {
    const { current: { lastDice }, update } = useContext(LocalStateContext);
    const [dice, setDice] = useState(lastDice ?? [1, 1]);
    const animStart = useRef<number>(0);

    const roll = () => {
        animStart.current = new Date().getTime();
        let interval: NodeJS.Timer | null = null;
        interval = setInterval(() => {
            const newDice = [Math.floor(Math.random() * 6) + 1, Math.floor(Math.random() * 6) + 1];
            setDice(newDice);
            if (animStart.current + 700 < new Date().getTime()) {
                interval && clearInterval(interval);
                update({ lastDice: newDice });
            }
        }, 50);
    };

    return (
        <div>
            <MonopolyButton onClick={roll} type="bigger">
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    position: 'relative',
                    paddingBottom: 4,
                }}>
                <Die value={dice[0]} scale={0.5}/>
                <Die value={dice[1]} scale={0.5} />
                </div>                                
            </MonopolyButton>
            
        </div>
    );
}