import { useContext, useEffect } from "react";
import { WebsocketContext } from "./Connection";

export default function useMessage(type: string, callback: (data: any) => void, deps: any[] = []) {
    const connection = useContext(WebsocketContext);

    useEffect(() => {
        const sub = connection.subscribe(type, (data) => {
            callback(data);
        });
        return () => {
            connection.unsubscribe(type, sub);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection, type, ...deps]);

}