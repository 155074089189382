import Client from './Client';
import Connection from './Connection';
import GameState from './GameState';
import Host from './Host';
import LandingPage from './LandingPage';
import useRoomID from './useRoomID';

function App() {
  const [room, name, hosting] = useRoomID();

  if (!room) {
    return <LandingPage />;
  }
  
  return (
    <div className="App">
      <Connection room={room} name={name}>
        <GameState>
        {hosting ? <Host /> : <Client />}
        </GameState>
      </Connection>
    </div>
  );
}

export default App;
