import { useMemo } from "react";

// Generate random name
function generateName() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export default function useRoomID(): [string, string, boolean] {
    const path = window.location.pathname;
    const parts = path.split('/');
    const room = parts[parts.length - 1];
    const hosting = parts[1].trim() === 'host'

    const name = useMemo(() => {
        let saved = window.localStorage.getItem("name");

        if (!saved) {
            saved = generateName();
            window.localStorage.setItem("name", saved);
        }

        return saved;
    }, []);
  
    return [room, name, hosting];
}