import { StyleSheet, css } from 'aphrodite';
import LeftPanel from "./host/LeftPanel";
import PlayerPieces from "./host/PlayerPieces";
import RightPanel from './host/RightPanel';

import bg from './imgs/bg.jpg';

const styles = StyleSheet.create({
    body: {
        height: '100vh',

        backgroundImage: `url(${bg})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'contain',
    },
    container: {
        zIndex: 0,
        position: 'fixed',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
});

export default function Host() {
    return (
        <div className={css(styles.body)}>
            <div className={css(styles.container)}>
                <PlayerPieces />
            </div>
            <LeftPanel />
            <RightPanel />
        </div>
    );
}