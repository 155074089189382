import React, { useCallback, useEffect, useRef, useState } from "react";

interface websocket {
    current: WebSocket | null;
    subscribe: (name: string, callback: (event: any) => void) => (data: any) => void;
    unsubscribe: (name: string, callback: (data: any) => void) => void;
}

export const WebsocketContext = React.createContext<websocket>({
    current: null,
    subscribe: () => () => {},
    unsubscribe: () => {}
});

interface Props {
    name: string;
    room: string;
    children: React.ReactElement | null;
}

export default function Connection({ name, room, children }: Props): React.ReactElement | null {
    const [ws, setWs] = useState<WebSocket | null>(null);
    const [connected, setConnected] = React.useState(false);

    const subs = useRef<{ [key: string]: Array<(data: any) => void> }>({});

    const contextMemo = React.useMemo(() => ({
        current: ws,
        subscribe: (name: string, callback: (data: any) => void): (data: any) => void => {
            if (!subs.current[name]) {
                subs.current[name] = [];
            }
            subs.current[name].push(callback);
            return callback;
        },
        unsubscribe: (name: string, callback: (data: any) => void) => {
            if (subs.current[name]) {
                subs.current[name] = subs.current[name].filter(cb => cb !== callback);
            }
        },
    }), [ws, subs]);

    const connect = useCallback((room: string, name: string) => {
        let url = 'wss://api.monopoly.kolhos.chichasov.es';

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            url = 'ws://' + window.location.hostname + ':6969';
        }

        const curWs = new WebSocket(url + '/ws?room=' + room + '&name=' + name);

        curWs.onerror = (event) => {
            console.log('WebSocket error: ', event);
        };

        curWs.onopen = () => {
            setConnected(true);
            console.log("Connection opened");
        };

        curWs.onclose = () => {
            setConnected(false);
            setWs(null);
            console.log("Connection closed");

            setTimeout(() => {
                connect(room, name);
            }, 1000);
        };

        curWs.onmessage = (event) => {
            console.log('New message: ', event.data);
            const data = JSON.parse(event.data);
            if (subs.current[data.type]) {
                subs.current[data.type].forEach(cb => cb(data));
            }
        };

        setWs(curWs);
    }, [setWs]);


    useEffect(() => {
        connect(room, name);
    }, [room, name, connect]);
        

    if (!connected) {
        return <>'Loading'</>;
    }

    return (
        <WebsocketContext.Provider value={contextMemo}>
            {children}
        </WebsocketContext.Provider>
    );
}