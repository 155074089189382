import { useContext } from "react";
import { StateContext } from "../GameState";
import { StyleSheet, css } from 'aphrodite';
import useWindowSize from "../useWindowSize";
import PlayerIcon from "../components/PlayerIcon";


const styles = StyleSheet.create({
    board: {
        height: '100vh',
    },
    boardImg: {
    },
    boardPiece: {
        position: 'absolute',
        zIndex: 1,
        // backgroundColor: 'white',
        // borderRadius: '50%',
        // padding: '0.5rem',
        opacity: 0.9,

        // width: '50px',
        // height: '50px',
    }
});


export default function PlayerPieces() {
    const state = useContext(StateContext);
    const [width, height] = useWindowSize();

    const renderPiece = (pid: string) => {
        const piece = state.players[pid];
        const step = piece.data.step ?? 0;

        console.log("Rendering piece", pid, step);

        let cell = step % 10;
        let side = Math.floor(step / 10) % 4;

        let y = 'calc(100vh - 50%)';
        let x = '';

        const bh = height;
        const space = (width - bh) / 2;

        const ratio = bh / 1500;

        const stepPixels = 122;

        const stepConverted = (stepPixels) * ratio;

    
        if (side === 2 || side === 1) {
            cell = 10 - cell;
        }

        if (side === 0 || side === 2) {
            y = 'calc(100vh - 45%*' + side + ' - 75px)';   
            x = `calc(${space}px + ${120 * ratio}px + ${stepConverted * cell}px - 25px)`;
        } else {
            y = `calc(${120 * ratio}px + ${stepConverted * cell}px - 25px)`;
            x = `calc(${space}px + ${side === 1 ? bh - 60*ratio - 25 : 0}px)`;
        }


        return (
            <div className={css(styles.boardPiece)} style={{
                right: x,
                top: y,
            }}>                
                <PlayerIcon 
                    value={state.players[pid].data.icon ?? 0} 
                    size={48}
                    small={true}
                    padding={4}
                    />
            </div>
        );
    };


    return (
        <div className={css(styles.board)}>
            <img alt="board" className={css(styles.board)} src="https://m.media-amazon.com/images/I/81oC5pYhh2L._AC_SL1500_.jpg">
            </img>
            {Object.keys(state.players).map((pid) => (
                renderPiece(pid)
            ))}
        </div>
    );
}
