import { useContext, useEffect, useRef } from "react";
import { WebsocketContext } from "../Connection";
import { StyleSheet, css } from "aphrodite";
import { LocalStateContext } from "./LocalState";
import { MoneySuggestionContext } from "./MoneySuggestion";
import { StateContext } from "../GameState";
import useRoomID from "../useRoomID";
import { PlayerProperty } from "./Properties";
import MonopolyButton from "../components/MonopolyButton";


const styles = StyleSheet.create({
    container: {
        padding: 32,
    },
    input: {
        width: "100%",
        fontSize: "2rem",
    },
    buttonRow: {
        display: "flex",
        justifyContent: "space-between",
        padding: '12px 0',
        width: "100%",
    },
    button: {
        flexGrow: 1,
        height: 60,
    },
});

function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export default function Steps() {
    const [_, name] = useRoomID();
    const { players } = useContext(StateContext);
    const { current: { step, properties: localProperties }, update } = useContext(LocalStateContext);
    const { clearAllSuggestions, clearStepSuggestions, addSuggestion } = useContext(MoneySuggestionContext);

    const prevStep = usePrevious(step);

    useEffect(() => {
        clearStepSuggestions();

        if (prevStep < step && step % 40 === 0) {
            addSuggestion(200, true);
        }

        if (step % 40 === 4) {
            addSuggestion(-200, true);
        }

        // Check if current player is over any other player's property
        Object.values(players).forEach(player => {
            if (player && player.name !== name) {
                player?.data?.properties?.forEach((property: PlayerProperty) => {
                    const suggID = `${property.name}-${player.name}`;
                    if (property.space === step % 40 && !property.mortgaged) {
                        addSuggestion(-property.rent[property.houses], true, suggID);
                    }
                });
            }
        });

        // Check if any other player is over current player's property
        Object.values(players).forEach(player => {
            if (player && player.name !== name) {
                localProperties?.forEach((property: PlayerProperty) => {
                    const suggID = `${property.name}-${player.name}`;
                    if (property.space === player.data.step % 40 && !property.mortgaged) {
                        addSuggestion(property.rent[property.houses], true, suggID);
                    }
                });
            }
        });
    }, [step, prevStep, addSuggestion, players, name, localProperties, clearStepSuggestions]);

    return (
        <>
            <div className={css(styles.buttonRow)}>
                <MonopolyButton onClick={() => {
                    update({ step: !step ? 0 : step - 1 });
                    clearAllSuggestions();
                }}
                    styles={[styles.button]}
                >
                    {'<<'}
                </MonopolyButton>
                <MonopolyButton onClick={() => {
                    update({ step: (step ?? 0) + 1 });
                    clearAllSuggestions();
                }}
                styles={[styles.button]}
                >
                    {'>>'}
                </MonopolyButton>
            </div>
        </>
    );
}