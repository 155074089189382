export interface TProperty {
    name: string;
    color: string;
    price: number;
    housePrice?: number;
    rent: Array<number>;
    mortgage: number;
    space: number;
}

export const PropertyList: {
    [key: string]: TProperty;
} = {
    'Mediterranean Avenue': {
        name: 'Mediterranean Avenue',
        color: 'brown',
        price: 60,
        housePrice: 50,
        rent: [2, 10, 30, 90, 160, 250],
        mortgage: 30,
        space: 1,
    },
    'Baltic Avenue': {
        name: 'Baltic Avenue',
        color: 'brown',
        price: 60,
        housePrice: 50,
        rent: [4, 20, 60, 180, 320, 450],
        mortgage: 30,
        space: 3,
    },
    'Oriental Avenue': {
        name: 'Oriental Avenue',
        color: 'lightblue',
        price: 100,
        housePrice: 50,
        rent: [6, 30, 90, 270, 400, 550],
        mortgage: 50,
        space: 6,
    },
    'Vermont Avenue': {
        name: 'Vermont Avenue',
        color: 'lightblue',
        price: 100,
        housePrice: 50,
        rent: [6, 30, 90, 270, 400, 550],
        mortgage: 50,
        space: 8,
    },
    'Connecticut Avenue': {
        name: 'Connecticut Avenue',
        color: 'lightblue',
        price: 120,
        housePrice: 50,
        rent: [8, 40, 100, 300, 450, 600],
        mortgage: 60,
        space: 9,
    },
    'St. Charles Place': {
        name: 'St. Charles Place',
        color: 'purple',
        price: 140,
        housePrice: 100,
        rent: [10, 50, 150, 450, 625, 750],
        mortgage: 70,
        space: 11,
    },
    'States Avenue': {
        name: 'States Avenue',
        color: 'purple',
        price: 140,
        housePrice: 100,
        rent: [10, 50, 150, 450, 625, 750],
        mortgage: 70,
        space: 13,
    },
    'Virginia Avenue': {
        name: 'Virginia Avenue',
        color: 'purple',
        price: 160,
        housePrice: 100,
        rent: [12, 60, 180, 500, 700, 900],
        mortgage: 80,
        space: 14,
    },
    'St. James Place': {
        name: 'St. James Place',
        color: 'orange',
        price: 180,
        housePrice: 100,
        rent: [14, 70, 200, 550, 750, 950],
        mortgage: 90,
        space: 16,
    },
    'Tennessee Avenue': {
        name: 'Tennessee Avenue',
        color: 'orange',
        price: 180,
        housePrice: 100,
        rent: [14, 70, 200, 550, 750, 950],
        mortgage: 90,
        space: 18,
    },
    'New York Avenue': {
        name: 'New York Avenue',
        color: 'orange',
        price: 200,
        housePrice: 100,
        rent: [16, 80, 220, 600, 800, 1000],
        mortgage: 100,
        space: 19,
    },
    'Kentucky Avenue': {
        name: 'Kentucky Avenue',
        color: 'red',
        price: 220,
        housePrice: 150,
        rent: [18, 90, 250, 700, 875, 1050],
        mortgage: 110,
        space: 21,
    },
    'Indiana Avenue': {
        name: 'Indiana Avenue',
        color: 'red',
        price: 220,
        housePrice: 150,
        rent: [18, 90, 250, 700, 875, 1050],
        mortgage: 110,
        space: 23,
    },
    'Illinois Avenue': {
        name: 'Illinois Avenue',
        color: 'red',
        price: 240,
        housePrice: 150,
        rent: [20, 100, 300, 750, 925, 1100],
        mortgage: 120,
        space: 24,
    },
    'Atlantic Avenue': {
        name: 'Atlantic Avenue',
        color: 'yellow',
        price: 260,
        housePrice: 150,
        rent: [22, 110, 330, 800, 975, 1150],
        mortgage: 130,
        space: 26,
    },
    'Ventnor Avenue': {
        name: 'Ventnor Avenue',
        color: 'yellow',
        price: 260,
        housePrice: 150,
        rent: [22, 110, 330, 800, 975, 1150],
        mortgage: 130,
        space: 27,
    },
    'Marvin Gardens': {
        name: 'Marvin Gardens',
        color: 'yellow',
        price: 280,
        housePrice: 150,
        rent: [28, 130, 390, 850, 1025, 1200],
        mortgage: 140,
        space: 29,
    },
    'Pacific Avenue': {
        name: 'Pacific Avenue',
        color: 'green',
        price: 300,
        housePrice: 200,
        rent: [35, 175, 500, 1100, 1300, 1500],
        mortgage: 150,
        space: 31,
    },
    'North Carolina Avenue': {
        name: 'North Carolina Avenue',
        color: 'green',
        price: 300,
        housePrice: 200,
        rent: [35, 175, 500, 1100, 1300, 1500],
        mortgage: 150,
        space: 32,
    },
    'Pennsylvania Avenue': {
        name: 'Pennsylvania Avenue',
        color: 'green',
        price: 320,
        housePrice: 200,
        rent: [50, 200, 600, 1400, 1700, 2000],
        mortgage: 160,
        space: 34,
    },
    'Park Place': {
        name: 'Park Place',
        color: 'darkblue',
        price: 350,
        housePrice: 200,
        rent: [55, 225, 750, 1700, 2000, 2250],
        mortgage: 175,
        space: 37,
    },
    'Boardwalk': {
        name: 'Boardwalk',
        color: 'darkblue',
        price: 400,
        housePrice: 200,
        rent: [60, 250, 800, 2000, 2200, 2400],
        mortgage: 200,
        space: 39,
    },
    'Electric Company': {
        name: 'Electric Company',
        color: 'black',
        price: 150,
        rent: [25, 25, 25, 25, 25, 25],
        mortgage: 75,
        space: 12,
    },
    'Water Works': {
        name: 'Water Works',
        color: 'black',
        price: 150,
        rent: [25, 25, 25, 25, 25, 25],
        mortgage: 75,
        space: 28,
    },
    'Reading Railroad': {
        name: 'Reading Railroad',
        color: 'black',
        price: 200,
        rent: [25, 50, 100, 200],
        mortgage: 100,
        space: 5,
    },
    'Pennsylvania Railroad': {
        name: 'Pennsylvania Railroad',
        color: 'black',
        price: 200,
        rent: [25, 50, 100, 200],
        mortgage: 100,
        space: 15,
    },
    'B. & O. Railroad': {
        name: 'B. & O. Railroad',
        color: 'black',
        price: 200,
        rent: [25, 50, 100, 200],
        mortgage: 100,
        space: 25,
    },
    'Short Line': {
        name: 'Short Line',
        color: 'black',
        price: 200,
        rent: [25, 50, 100, 200],
        mortgage: 100,
        space: 35,
    },
}; 