import { StyleSheet, css } from "aphrodite";
import "./monopolyFont.css";


const styles = StyleSheet.create({
    button: {
        fontSize: "1.4rem",

        margin: 12,
        padding: '4px 8px',

        background: 'white',
        boxShadow: '2px 2px 2px rgba(0,0,0,0.5)',

        border: '1px solid black',
        borderRadius: 4,
        fontFamily: 'monopoly',

        ':hover': {
            background: '#eee',
        },
        ':focus': {
            boxShadow: '0 0 2px rgba(0,0,0,0.5)',
        },
    },
    bigButton: {
        margin: 0,
        fontSize: "2.5rem",
        width: "100%",
    },
});

type Props = React.HTMLProps<HTMLInputElement> & {
    fieldSize?: "big" | "small";
};

export default function MonopolyInput(props: Props) {
    const { fieldSize = "small", ...rest } = props;


    return (
        <input className={css([
            styles.button,
            fieldSize === "big" && styles.bigButton,
            ])} {...rest}>
        </input>
    );
}