import { StyleSheet, css } from "aphrodite";
import Properties from "./client/Properties";
import Money from "./client/Money";
import Dice from "./client/Dice";
import Chance from "./client/Chance";
import LocalState from "./client/LocalState";
import Steps from "./client/Steps";
import Name from "./client/Name";
import MoneySuggestion from "./client/MoneySuggestion";

import bg from './imgs/bg.jpg';
import MonopolyCard from "./components/MonopolyCard";


const styles = StyleSheet.create({
    container: {
        padding: 32,
        overflow: 'auto',
    },
    input: {
        width: "100%",
        fontSize: "2rem",
    },
    buttonRow: {
        display: "flex",
        justifyContent: "space-between",
        padding: '12px 0',
        width: "100%",
    },
    button: {
        // height: 48,
        fontSize: "2rem",
        flexGrow: 1,
        height: 60,
    },
    background: {
        backgroundImage: `url(${bg})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'contain',
        height: '100%',
    }
});

export default function Client() {

    return (
        <div className={css(styles.background)}>
        <LocalState>
            <MoneySuggestion>
                <div className={css(styles.container)}>
                    <Name />
                    <Steps />
                    <Dice />
                    <Money />
                    <Properties />
                    <Chance />
                </div>
            </MoneySuggestion>
        </LocalState>
        </div>
    );
}
