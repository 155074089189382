import { StyleSheet, css } from "aphrodite";
import "./monopolyFont.css";


const styles = StyleSheet.create({
    button: {
        fontSize: "1.4rem",

        margin: 12,
        padding: '4px 8px',

        background: 'white',
        boxShadow: '2px 2px 2px rgba(0,0,0,0.5)',

        border: '1px solid black',
        borderRadius: 4,
        fontFamily: 'monopoly',

        ':hover': {
            
        },
        ':active': {
            boxShadow: '0 0 2px rgba(0,0,0,0.5)',
            background: '#eee',
        },
    },
    big: {
        fontSize: '2.5rem',
        width: '100%',
    },
    bigger: {
        width: '100%',
        fontSize: '3.5rem',
        paddingTop: 16,
    },
    compact: {
        margin: 0,
    },
});

type Props = React.HTMLProps<HTMLButtonElement> & {
    type?: "big" | "small" | "bigger" | "compact";
    styles?: any;
};

export default function MonopolyButton(props: Props) {
    const { type = "small", ...rest } = props;

    return (
        <button
            type="button"
            {...rest}
            className={css([
                styles.button,
                type === "big" && styles.big,
                type === "bigger" && styles.bigger,
                type === "compact" && styles.compact,
                ...(props.styles || []),
            ])}>
            {props.children}
        </button>
    );
}