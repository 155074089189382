import PlayerList from './PlayerList';

export default function RightPanel() {
    return (
        <div style={{
            padding: 16,
            zIndex: 2,
            position: 'absolute',
            right: 0,
            top: 0,
        }}>
            <PlayerList />
        </div>
    );
}