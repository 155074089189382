import { StyleSheet, css } from 'aphrodite';


import boat from '../imgs/pieces/boat.png';
import car from '../imgs/pieces/car.png';
import cat from '../imgs/pieces/cat.png';
import dog from '../imgs/pieces/dog.png';
import hat from '../imgs/pieces/hat.png';
import shoe from '../imgs/pieces/shoe.png';
import thimble from '../imgs/pieces/thimble.png';
import wheelbarrow from '../imgs/pieces/wheelbarrow.png';

const styles = StyleSheet.create({
    bg: {
        background: '#ddd',
        border: '2px solid black',
        borderRadius: '50%',        
    },
    big: {
        margin: '10px 20px',
        padding: 12,
    },
});

const AllIcons = [
    boat,
    car,
    cat,
    dog,
    hat,
    shoe,
    thimble,
    wheelbarrow,
];

interface Props {
    value: number;
    size?: number;
    small?: boolean;
    padding?: number;
    onClick?: () => void;
}

export default function PlayerIcon(props: Props) {
    const size = props.size ?? 48;
    return (
        <div onClick={props.onClick} className={css([
            styles.bg,
            !props.small && styles.big,
        ])} style={{
            width: size + (props.padding ?? 0) * 2,
            height: size + (props.padding ?? 0) * 2,
            aspectRatio: '1',
        }}>
            <img
                src={AllIcons[props.value % AllIcons.length]}
                width={size}
                height={size}
                alt="Icon"
                style={{
                    margin: props.padding ?? undefined,
                }}
                
                />
        </div>
    );
}