import React, { useCallback, useMemo, useRef, useState } from "react";

interface TSuggestion {
    id?: string | undefined;
    value: number;
    step: boolean;
}

interface TMoneySuggestion {
    suggestions: Array<TSuggestion>;
    addSuggestion: (suggestion: number, step: boolean, id?: string | undefined) => void;
    removeSuggestion: (index: number) => void;
    clearStepSuggestions: () => void;
    clearAllSuggestions: () => void;
}

export const MoneySuggestionContext = React.createContext<TMoneySuggestion>({
    suggestions: [],
    addSuggestion: () => {},
    removeSuggestion: () => {},
    clearAllSuggestions: () => {},
    clearStepSuggestions: () => {},
});

export default function MoneySuggestion({children}: any) {
    const [suggestions, setSuggestions] = useState<Array<TSuggestion>>([]);
    const recentSuggestions = useRef<Array<string>>([]);

    const addSuggestion = useCallback((suggestion: number, step: boolean, id: string | undefined = undefined) => {
        if (id && recentSuggestions.current.includes(id)) {
            return;
        }

        setSuggestions(suggestions => [...suggestions, { value: suggestion, step }]);
    }, [setSuggestions]);

    const removeSuggestion = useCallback((index: number) => {
        setSuggestions(suggestions => {
            const s = suggestions[index];

            if (s.id) {
                recentSuggestions.current.push(s.id);
                if (recentSuggestions.current.length > 3) {
                    recentSuggestions.current.shift();
                }
            }
            
            return suggestions.filter((_, i) => i !== index)
        });
    }, [setSuggestions, recentSuggestions]);

    const clearAllSuggestions = useCallback(() => {
        setSuggestions([]);
    }, [setSuggestions]);

    const clearStepSuggestions = useCallback(() => {
        setSuggestions(suggestions => suggestions.filter(s => !s.step));
    }, [setSuggestions]);

    const memoizedContext = useMemo(() => ({
        suggestions,
        addSuggestion,
        removeSuggestion,
        clearAllSuggestions,
        clearStepSuggestions,
    }), [suggestions, addSuggestion, removeSuggestion, clearAllSuggestions, clearStepSuggestions]);

    return (
        <MoneySuggestionContext.Provider value={memoizedContext}>
        {children}
        </MoneySuggestionContext.Provider>
    );
}