import { useContext } from "react";
import MonopolyButton from "../components/MonopolyButton";
import { LocalStateContext } from "./LocalState";

const Chances = `Advance to Boardwalk
Advance to Go (Collect $200)
Advance to Illinois Avenue. If you pass Go, collect $200
Advance to St. Charles Place. If you pass Go, collect $200
Advance to the nearest Railroad. If unowned, you may buy it from the Bank. If owned, pay wonder twice the rental to which they are otherwise entitled
Advance to the nearest Railroad. If unowned, you may buy it from the Bank. If owned, pay wonder twice the rental to which they are otherwise entitled
Advance token to nearest Utility. If unowned, you may buy it from the Bank. If owned, throw dice and pay owner a total ten times amount thrown.
Bank pays you dividend of $50
Get Out of Jail Free
Go Back 3 Spaces
Go to Jail. Go directly to Jail, do not pass Go, do not collect $200
Make general repairs on all your property. For each house pay $25. For each hotel pay $100
Speeding fine $15
Take a trip to Reading Railroad. If you pass Go, collect $200
You have been elected Chairman of the Board. Pay each player $50
Your building loan matures. Collect $150`.split('\n');

const Chests = `Advance to Go (Collect $200)
Bank error in your favor. Collect $200
Doctor’s fee. Pay $50
From sale of stock you get $50
Get Out of Jail Free
Go to Jail. Go directly to jail, do not pass Go, do not collect $200
Holiday fund matures. Receive $100
Income tax refund. Collect $20
It is your birthday. Collect $10 from every player
Life insurance matures. Collect $100
Pay hospital fees of $100
Pay school fees of $50
Receive $25 consultancy fee
You are assessed for street repair. $40 per house. $115 per hotel
You have won second prize in a beauty contest. Collect $10
You inherit $100`.split('\n');


export default function Chance() {
    const { current: { chances }, update } = useContext(LocalStateContext);

    const pushChance = (chance: string) => {
        if (chances && chances.length > 5) {
            update({ chances: [chance, ...chances.slice(0, 5)]})
        } else {
            update({ chances: [chance, ...(chances ?? [])]});
        }
    };

    return (
        <div>
            <h2>Chance</h2>
            <div style={{ display: 'flex', width: '100%'}}>
                <MonopolyButton type="big" style={{ flexGrow: 1 }} onClick={() => {
                    const c = Chances[Math.floor(Math.random() * Chances.length)];
                    alert(c);
                    pushChance(c);  
                }}>
                    Chance
                </MonopolyButton>
                <MonopolyButton type="big" style={{ flexGrow: 1 }} onClick={() => {
                    const c = Chests[Math.floor(Math.random() * Chests.length)];
                    alert(c);  
                    pushChance(c);
                }}>
                     Chest
                </MonopolyButton>
            </div>
            <hr/>
            <h3>History</h3>
            {(chances ?? []).map((c: string, i: number) => {
                return (<p key={i}>{c}</p>);
            })}
        </div>
    );
}